import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';

const QuartzteqPage = () => {
  const data = useStaticQuery(graphql`
    query {
      metrohm: file(relativePath: { eq: "metrohm.png" }) {
        ...projectImage
      }
      ui: file(relativePath: { eq: "metrohm-ui.png" }) {
        ...projectImage
      }
    }
  `);

  return (
    <Layout>
      <div id="main">
        <div className="inner">
          <h1>Metrohm OMNIS – Titration on a whole new level</h1>
          <ul>
            <li>
              Developed the <strong>statistics/analytics feature</strong> for a
              chemical analysis software (C#, MSSQL)
            </li>
            <li>
              Coached five teams on <strong>clean code</strong>, pair
              programming
            </li>
          </ul>
          <span className="image main">
            <Img fluid={data.metrohm.childImageSharp.fluid} alt="metrohm" />
          </span>
          <span className="image main">
            <Img fluid={data.ui.childImageSharp.fluid} alt="metrohm-ui" />
          </span>
        </div>
      </div>
    </Layout>
  );
};

export default QuartzteqPage;
